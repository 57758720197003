.projects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}
.projects__items {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.projects__items:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}
.projects__items-image {
    border-radius: 1.5rem;
    overflow: hidden;

}

.projects__items h2 {
    margin: 1.2rem 0 2rem;
}

.projects__items-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

@media screen and (max-width:1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;        
    }
}
@media screen and (max-width:600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}